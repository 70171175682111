module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-90},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z774Q487TC"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Curral-Stephen Avocat","short_name":"Curral-Stephen Avocat","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"307209a54a488ff7017e2884de65896f"},
    },{
      plugin: require('../node_modules/@atido/gatsby-theme-showcase/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalyticsID":"G-Z774Q487TC","anchorOffset":-90,"siteTitleForManifest":"Curral-Stephen Avocat","contentfulConfig":{"spaceId":"frmv79i0nxv8","accessToken":"6ptAEEF6o0h0F2dmkTRdNQHQBfCjuz4_RdDLM4uAUqc"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
